import * as React from "react";
import { Theme } from "@mui/material/styles";
import { SxProps } from "@mui/system";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Typography from "../components/Typography";
import "./ProductValues.css";
const bandImage = require("../../images/band.jpg");

const item: SxProps<Theme> = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  px: 5,
};

function ProductValues() {
  return (
    <Box
      component="section"
      sx={{ display: "flex", overflow: "hidden", bgcolor: "secondary.light" }}
    >
      <Container sx={{ mt: 15, mb: 30, display: "flex", position: "relative" }}>
        <Grid container spacing={5}>
          <Grid item xs={12} md={4}>
            <Box sx={item}>
              <Box
                component="img"
                src={bandImage}
                alt="suitcase"
                sx={{ height: 150 }}
              />
              <Typography variant="h6" sx={{ my: 5 }}>
                Something
              </Typography>
              <Typography variant="h5">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box sx={item}>
              <Box
                component="img"
                src={bandImage}
                alt="graph"
                sx={{ height: 150 }}
              />
              <Typography variant="h6" sx={{ my: 5 }}>
                More Stuff
              </Typography>
              <Typography variant="h5">
                Duis aute irure dolor in reprehenderit in voluptate velit esse
                cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                cupidatat non proident, sunt in culpa qui officia deserunt
                mollit anim id est laborum.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box sx={item}>
              <Box
                component="img"
                src={bandImage}
                alt="clock"
                sx={{ height: 150 }}
              />
              <Typography variant="h6" sx={{ my: 5 }}>
                And even more
              </Typography>
              <Typography variant="h5">
                Pharetra sit amet aliquam id diam maecenas ultricies mi eget.
                Egestas congue quisque egestas diam in arcu cursus euismod.
                Blandit volutpat maecenas volutpat blandit aliquam. Viverra
                aliquet eget sit amet tellus cras adipiscing enim.
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default ProductValues;
